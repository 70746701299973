package com.cjrodriguezproductions.divine_motors.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.cjrodriguezproductions.divine_motors.components.layouts.DefaultPageLayout
import com.cjrodriguezproductions.divine_motors.components.vehicle_card.HoverImageStyle
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun AboutPage() {
    val selectedSectionId = remember { mutableStateOf("/about") }
    val aboutText =
        "DIVINE MOTORS LTD was incorporated in PORT HARCOURT, Nigeria. It was officially registered on 11th September 1998, marking the beginning of our journey in the automotive industry. With over two decades of experience, we have established ourselves as a leading name in the Nigerian automotive market.\n\n" +
                "Our foundation was built on a vision to provide high-quality vehicles and exceptional service to our customers. From humble beginnings in Port Harcourt, we have grown to become a respected name across Nigeria, known for our reliability, integrity, and commitment to customer satisfaction.\n\n" +
                "Over the years, we have adapted to the changing landscape of the automotive industry, embracing new technologies and eco-friendly options. We're proud to now offer hybrid and electric vehicles, aligning with global efforts towards sustainable transportation.\n\n" +
                "As we look to the future, DIVINE MOTORS LTD remains committed to our founding principles of quality, integrity, and customer satisfaction. We continue to expand our services, improve our offerings, and strive to be at the forefront of automotive excellence in Nigeria.\n\n" +
                "Whether you're looking for your first car, upgrading to a luxury vehicle, or need a fleet for your business, DIVINE MOTORS LTD is here to serve you. Visit our showroom in Port Harcourt to experience the DIVINE difference – where your automotive dreams become reality. \n\n" +
                "Our mission as a vehicle leasing and maintenance company is to provide flexible," +
                " reliable and cost-effective transportation solution while ensuring the highest" +
                " standards of safety, convenience and customer satisfaction."

    DefaultPageLayout(
        selectedId = selectedSectionId.value,
        onSelectItem = { selectedSectionId.value = it }
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = 20.px)
        ) {
            H1(attrs = Modifier
                .textAlign(TextAlign.Center)
                .color(ThemeColor.DarkGreen.color)
                .fillMaxWidth()
                .padding(all = 40.px)
                .toAttrs { }
            ) {
                Text("About Us")
            }

            SimpleGrid(
                numColumns = numColumns(1, sm = 1, xl = 2),
                modifier = Modifier.gap(40.px)
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .padding(bottom = 40.px)
            ) {
                Column(verticalArrangement = Arrangement.SpaceBetween) {
                    H5(
                        attrs = Modifier.fillMaxHeight()
                            .textAlign(TextAlign.Justify)
                            .whiteSpace(WhiteSpace.PreWrap)
                            .toAttrs { }
                    ) {
                        Text(aboutText)
//                        SpanText(
//                            text = aboutText,
//                            modifier = Modifier.margin(bottom = 10.px).whiteSpace(WhiteSpace.PreWrap)
//                        )
                    }

//                    H3(attrs = SpanTextStyle
//                        .toModifier()
//                        .margin(right = 20.px, bottom = 20.px)
//                        .textAlign(TextAlign.Justify)
//                        .toAttrs { }
//                    ) {
//                        SpanText(
//                            "Our mission as a vehicle leasing and maintenance company is to provide flexible," +
//                                    " reliable and cost-effective transportation solution while ensuring the highest" +
//                                    " standards of safety, convenience and customer satisfaction."
//                        )
//                    }
                }
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(Height.FitContent)
                        .overflow(Overflow.Hidden)
                        .borderRadius(r = 10.px)
                ) {
                    Image(
                        //src = "third_hilux.svg",
                        src = "toyota_hilux_latest.jpg",
                        modifier = Modifier.fillMaxWidth()
                            //.height(100.percent)
                            //.height(400.px)
                            .objectFit(ObjectFit.Cover)
                            .then(HoverImageStyle.toModifier())
                    )
                }
            }
        }
    }
}