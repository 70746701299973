package com.cjrodriguezproductions.divine_motors.components.vehicle_card

import androidx.compose.runtime.Composable
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.style.animation.Keyframes
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

@Composable
fun VehicleImageCard(
    modifier: Modifier = Modifier,
    imageUrl: String,
    title: String,
    onClick: ()-> Unit = {}
) {
    Column(
        modifier = modifier
            .margin(bottom = 20.px)
    ) {
        Box(modifier = Modifier
            .fillMaxWidth()
            .height(Height.FitContent)
            .overflow(Overflow.Hidden)
            .borderRadius(r = 10.px)
        ) {
            Image(
                src = imageUrl,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(auto)
                    .objectFit(ObjectFit.Cover)
            .then(HoverImageStyle.toModifier())
            )
//            Surface(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(500.px)
//                    .borderRadius(r = 10.px)
//                    .boxShadow(offsetY = 5.px, blurRadius = 2.px, color = ThemeColor.Grey.color)
//                    .backgroundColor(ThemeColor.LightGreenTransparent.color)
//                    .backgroundBlendMode(BackgroundBlendMode.Overlay)
//            ){}
        }
        H4 (
            Modifier
            .fontWeight(FontWeight.Bold)
            .textAlign(TextAlign.Center)
            .fillMaxWidth()
            .margin(topBottom = 20.px)
            .toAttrs()) {
            Text(title)
        }
        Button(
            modifier = Modifier
                .color(ThemeColor.White.color)
                .borderRadius(r = 10.px).height(50.px)
                .fillMaxWidth(),
            onClick = {onClick.invoke()}
        ) {
            Text("Enquire")
        }
    }
}

val upDownAnim = Keyframes {
    each(0.percent, 100.percent) {
        Modifier.translateY((-20).px)
    }
    50.percent {
        Modifier.translateY(20.px)
    }
}

val zoomIn = Keyframes {
    from { Modifier.scale(0) }
    to { Modifier.scale(1) }
}

val zoomOut = Keyframes {
    from { Modifier.scale(1) }
    to { Modifier.scale(0.9) }
}

val slideAnimation = Keyframes {
    0.percent {
        Modifier.translateX((-100).percent)
    }
    100.percent {
        Modifier.translateX(0.percent)
    }
}

//val HoverImageStyle by ComponentStyle {
//    hover {
//        Modifier.animation(
//            zoomIn.toAnimation(
//            duration = 1.s,
//            timingFunction = AnimationTimingFunction.Ease,
//            iterationCount = AnimationIterationCount.of(1)
//        ))
//    }
//}

val HoverImageStyle by ComponentStyle {
    base {
        Modifier
            .transition(CSSTransition(property = "transform", duration = 300.ms))
            .transform { scale(1.0) }
    }
    hover {
        Modifier
            .transition(CSSTransition(property = "transform", duration = 300.ms))
            .transform { scale(1.1) }
    }
}