package com.cjrodriguezproductions.divine_motors

import androidx.compose.runtime.*
import com.cjrodriguezproductions.divine_motors.pages.Theme
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerStyleBase
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.MutableSilkTheme
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.button
import com.varabyte.kobweb.silk.theme.colors.palette.tab
import org.jetbrains.compose.web.css.*

@InitSilk
fun updateTheme(ctx: InitSilkContext) {
    ctx.stylesheet.registerStyleBase("body") {
        Modifier.fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(16.px)
            .lineHeight(1.5)
    }
    ctx.stylesheet.registerStyleBase("H1") {
        Modifier.fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(40.px)
            .lineHeight(1.5)
    }
    ctx.stylesheet.registerStyleBase("H2") {
        Modifier.fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(32.px)
            .lineHeight(1.5)
    }
    ctx.stylesheet.registerStyleBase("H3") {
        Modifier.fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(24.px)
            .lineHeight(1.5)
    }
    ctx.stylesheet.registerStyleBase("H4") {
        Modifier.fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(20.px)
            .lineHeight(1.5)
    }
    ctx.stylesheet.registerStyleBase("H5") {
        Modifier.fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(18.px)
            .lineHeight(1.5)
    }
    ctx.stylesheet.registerStyleBase("H6") {
        Modifier.fontFamily("Ubuntu", "Roboto", "Arial", "Helvetica", "sans-serif")
            .fontSize(16.px)
            .lineHeight(1.5)
    }
    ctx.stylesheet.registerStyleBase("button"){
        Modifier.fontSize(18.px)
    }
    ctx.theme.palettes.light.apply {
        background = Colors.White
        button.apply {
            default = ThemeColor.DarkGreen.color
//            hover = Colors.White
            focus = ThemeColor.LightGreen.color
//            pressed = Colors.White
        }
    }
}

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    SilkApp {
        Surface(SmoothColorStyle.toModifier().minHeight(100.vh)) {
            content()
        }
    }
}

