package com.cjrodriguezproductions.divine_motors.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.cjrodriguezproductions.divine_motors.components.layouts.DefaultPageLayout
import com.cjrodriguezproductions.divine_motors.components.reasons_card.ReasonCard
import com.varabyte.kobweb.compose.css.AlignContent
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text

@Page
@Composable
fun ServicesPage() {
    val selectedSectionId = remember { mutableStateOf("/services") }
    DefaultPageLayout(
        selectedId = selectedSectionId.value,
        onSelectItem = { selectedSectionId.value = it }
    ) {
        val breakpoint = rememberBreakpoint()
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .padding(leftRight = 20.px)
        ) {
            H1(attrs = SpanTextStyle
                .toModifier()
                .textAlign(TextAlign.Center)
                .color(ThemeColor.DarkGreen.color)
                .fillMaxWidth()
                .padding(all = 40.px)
                .toAttrs { }
            ) {
                Text("Services")
            }

//            Row(
//                modifier = Modifier.flexWrap(FlexWrap.Wrap).margin(leftRight = 40.px),
//                horizontalArrangement = Arrangement.SpaceBetween,
//            ) {
//                ReasonCard(
//                    imageUrl = "leasing_icon.svg",
//                    title = "Leasing",
//                    subTitle = "We offer various lease plans, allowing customers to use new or used vehicles for a fixed period."
//                )
//                ReasonCard(
//                    imageUrl = "supply_icon.svg",
//                    title = "Supply",
//                    subTitle = "Manages a fleet of vehicles to ensure availability and quick delivery to clients."
//                )
//                ReasonCard(
//                    imageUrl = "maintenance_icon.svg",
//                    title = "Maintenance",
//                    subTitle = "This includes  servicing, repairs, and sometimes insurance coverage."
//                )
//            }

            SimpleGrid(
                numColumns = numColumns(1, sm = 2, lg = 3),
                modifier = Modifier.gap(60.px)
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .padding(bottom = 40.px)
            ) {
                ReasonCard(
                    modifier = Modifier,
                    imageUrl = "leasing_icon.svg",
                    title = "Leasing",
                    subTitle = "We offer various lease plans, allowing customers to use new or used vehicles for a fixed period.",
                    breakpoint = breakpoint
                )
                ReasonCard(
                    modifier = Modifier,
                    imageUrl = "supply_icon.svg",
                    title = "Supply",
                    subTitle = "Manages a fleet of vehicles to ensure availability and quick delivery to clients.",
                    breakpoint = breakpoint
                )
                ReasonCard(
                    modifier = Modifier,
                    imageUrl = "maintenance_icon.svg",
                    title = "Maintenance",
                    subTitle = "This includes  servicing, repairs, and sometimes insurance coverage.",
                    breakpoint = breakpoint
                )
            }
        }
    }
}