package com.cjrodriguezproductions.divine_motors.pages

import androidx.compose.runtime.*
import com.cjrodriguezproductions.divine_motors.components.collapsable_faq.CollapsibleFAQ
import com.cjrodriguezproductions.divine_motors.components.input_fields.InfiniteScrollingImageCarouselStage
import com.cjrodriguezproductions.divine_motors.components.layouts.DefaultPageLayout
import com.cjrodriguezproductions.divine_motors.components.reasons_card.ReasonCard
import com.cjrodriguezproductions.divine_motors.components.vehicle_card.VehicleImageCard
import com.cjrodriguezproductions.divine_motors.worker.EchoWorker
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.selectors.focus
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.worker.rememberWorker
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*


@Page
@Composable
fun HomePage() {
    val worker = rememberWorker { EchoWorker { output -> console.log("Echoed: $output") } }
    LaunchedEffect(Unit) {
        worker.postInput("Hello, worker!")
    }
    val ctx = rememberPageContext()

    val selectedSectionId = remember { mutableStateOf("/") }
    DefaultPageLayout(
        selectedId = selectedSectionId.value,
        onSelectItem = {selectedSectionId.value = it}
    ){
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .height(Height.MaxContent)
        ) {
//            InfiniteScrollingImageCarouselStage(
//                imagesList = listOf(
//                    "first hilux focus.png",
//                    "coaster_updated.png",
//                    "hummer foocus.jpg",
//                    "second_coaster.svg",
//                    "second hilux big focus.png"
//                )
//            )

            InfiniteScrollingImageCarouselStage(
                imagesList = listOf(
                    "flux_hilux_1024.png",
                    "flux_hilux_1024_2.png",
                    "flux_coaster_1024.png",
                    "flux_coaster_1024_2.png",
                    "flux_hummer_1024.png"
                )
            )
        }
        val breakpoint = rememberBreakpoint()
        val topVehiclesFont = if (breakpoint == Breakpoint.SM) 1.5.cssRem else 2.cssRem

//        H2(
//            attrs = SpanTextStyle
//            .toModifier()
//            .textAlign(TextAlign.Center)
//            .fillMaxWidth()
//            .fontSize(if (breakpoint == Breakpoint.SM) 20.px else 32.px)
//            //.padding(all = 40.px)
//            .toAttrs { }
//        ) { Text("Top Vehicles") }

        SpanText(
            text = "Top Vehicles",
            modifier = SpanTextStyle
                .toModifier()
                .textAlign(TextAlign.Center)
                .fillMaxWidth()
                //.fontSize(if (breakpoint == Breakpoint.SM) 20.px else 32.px)
                .fontSize(topVehiclesFont)
                .padding(all = 40.px)
        )

        SimpleGrid(
            numColumns = numColumns(1, sm = 1, lg = 3),
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = 20.px)
                .gap(60.px)
        ) {
            VehicleImageCard(
                modifier = Modifier.fillMaxWidth(),
                //imageUrl = "toyota_browser_updated.jpg",
                imageUrl = "flux_hilux.png",
                title = "Hilux",
                onClick = {
                    ctx.router.navigateTo("/contact?id=Hilux")
                }
            )

            VehicleImageCard(
                //imageUrl = "coaster_updated.png",
                imageUrl = "flux_coaster.png",
                title = "Coaster",
                onClick = {
                    ctx.router.navigateTo("/contact?id=Coaster")
                }
            )

            VehicleImageCard(
                //imageUrl = "hummer_updated.png",
                imageUrl = "flux_hummer.png",
                title = "Hummer",
                onClick = {
                    ctx.router.navigateTo("/contact?id=Hummer")
                }
            )
        }

        SpanText(
            text = "Why Divine Motors?",
            modifier = SpanTextStyle
                .toModifier()
                .textAlign(TextAlign.Center)
                .fillMaxWidth()
                .fontSize(topVehiclesFont)
                .padding(all = 40.px)
        )

//        H2(attrs = SpanTextStyle
//            .toModifier()
//            .textAlign(TextAlign.Center)
//            .fillMaxWidth()
//            .fontSize(if (breakpoint == Breakpoint.SM) 20.px else 32.px)
//            .padding(topBottom = 40.px)
//            .toAttrs { }
//        ) {
//            Text("Why Divine Motors?")
//        }

        SimpleGrid(
            numColumns = numColumns(1, sm = 1, lg = 3),
            modifier = Modifier
                .padding(leftRight = 40.px)
                .gap(60.px)
        ) {
            ReasonCard(
                imageUrl = "money_icon.svg",
                title = "Affordable price",
                subTitle = "Get reasonable prices when you work with us",
                breakpoint = breakpoint
            )

            ReasonCard(
                imageUrl = "calendar_icon.svg",
                title = "Book Anytime",
                subTitle = "You can reserve a vehicle anytime you want",
                breakpoint = breakpoint
            )

            ReasonCard(
                imageUrl = "maintenance_icon.svg",
                title = "Maintenance",
                subTitle = "Get regular maintenance when using our vehicles",
                breakpoint = breakpoint
            )
        }

        H4 (attrs = SpanTextStyle
            .toModifier()
            .textAlign(TextAlign.Center)
            .fillMaxWidth()
            .fontSize(if (breakpoint == Breakpoint.SM) 16.px else 20.px)
            .padding(top = 40.px)
            .toAttrs { }
        ) {
            Text("Frequently Asked Questions")
        }
        H2(attrs = SpanTextStyle
            .toModifier()
            .id("FAQ")
            .textAlign(TextAlign.Center)
            .fillMaxWidth()
            .fontSize(topVehiclesFont)
            .padding(bottom = 40.px)
            .toAttrs { }
        ) {
            Text("Your questions answered!")
        }

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(leftRight = if (breakpoint != Breakpoint.SM) 0.px else 20.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            CollapsibleFAQ(
                id = "collapse1",
                color = ThemeColor.LightGreen.color,
                title = "What types of Vehicle do you lease?",
                subtitle = """
                      We specialize in leasing three types of vehicles: Hilux, Hummer, 
                      and Coaster. Whether you need a reliable pickup, a robust SUV,
                       or a spacious bus, we have the perfect option for your needs.
                """.trimIndent(),
                breakpoint = breakpoint
            )
            CollapsibleFAQ(
                modifier = Modifier
                    .margin(topBottom = 20.px)
                    .boxShadow(offsetY = 1.px, blurRadius = 0.px, color = Colors.LightGray),
                id = "collapse2",
                color = ThemeColor.White.color,
                title = "How do I schedule a tour to see out Vehicles?",
                subtitle = """
           To schedule a tour to see our vehicles,
            please fill out the "Contact Us" section on our website.
             We'll get in touch with you shortly to arrange your visit.
                """.trimIndent(),
                breakpoint = breakpoint
            )
        }
    }
}

val InputStyle = CssStyle {
    base {
        Modifier.border(
            width = 2.px,
            style = LineStyle.Solid,
            color = Theme.LightGreen.color
        )
    }
    focus {
        Modifier.border(
            width = 2.px,
            style = LineStyle.Solid,
            color = Theme.DarkGreen.color
        )
    }
}

enum class Theme(val color: CSSColorValue) {
    DarkGreen(color = rgb(r = 20, g = 136, b = 97)),
    LightGreen(color = rgb(r = 216, g = 237, b = 230)),
    Black(color = rgb(r = 38, g = 39, b = 39)),
    White(color = rgb(r = 255, g = 255, b = 255)),
}

enum class ThemeColor(val color: Color) {
    DarkGreen(color = Color.rgb(r = 20, g = 136, b = 97)),
    LightGreen(color = Color.rgb(r = 216, g = 237, b = 230)),
    //LightGreenTransparent(color = Color.rgba(r = 0, g = 0, b = 0, a = 700)),
    LightGreenTransparent(color = Color.rgba(r = 0, g = 0, b = 0, a = 0.5F)),
    OverlayGreen(color = Color.rgba(0, 0, 0, a = 900)),
    Black(color = Color.rgb(r = 38, g = 39, b = 39)),
    White(color = Color.rgb(r = 255, g = 255, b = 255)),
    Grey(color = Color.rgb(r = 210, g = 221, b = 217)),
}

val NavBarLinkStyle by ComponentStyle {
    base {
        Modifier
            .color(ThemeColor.Black.color)
            .fontWeight(400)
            .letterSpacing(0.8.px)
            .fontSize(18.px)
            .opacity(1)
            .textDecorationLine(TextDecorationLine.None)
    }
    link { Modifier.color(ThemeColor.Black.color) }
    visited { Modifier.color(ThemeColor.Black.color) }
    hover { Modifier.textDecorationLine(TextDecorationLine.Underline) }
    active { Modifier.textDecorationLine(TextDecorationLine.Underline) }
}
