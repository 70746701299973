package com.cjrodriguezproductions.divine_motors.components.navigation

import androidx.compose.runtime.Composable
import com.cjrodriguezproductions.divine_motors.pages.Theme
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px

@Composable
fun DivineNavBarBrand(
    isColored: Boolean = true,
    shouldShowLogo: Boolean = true,
    modifier: Modifier = Modifier
){
    val ctx = rememberPageContext()
    Row(
        modifier = modifier
            .backgroundColor(Colors.Transparent)
            .color(if (isColored) Theme.DarkGreen.color else ThemeColor.White.color)
            .fontSize(FontSize.Larger)
            .fontWeight(FontWeight.Bold)
            .fontSize(16.px)
            .onClick { ctx.router.navigateTo("/") }
            .cursor(Cursor.Pointer),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ){
        Image(
            src = if (isColored) "final_logo_remove_bg.svg" else "final_logo_black_white_2.svg",
            height = 30,
            modifier = Modifier.objectFit(ObjectFit.Cover)
                .margin(right = 20.px)
        )

        if(shouldShowLogo){
            SpanText("Divine Motors", modifier = Modifier.align(Alignment.Bottom))
        }
    }
}

