package com.cjrodriguezproductions.divine_motors.components.navigation

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.key
import com.cjrodriguezproductions.divine_motors.pages.NavBarLinkStyle
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.browser.dom.ElementTarget
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.textDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.CloseIcon
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.overlay.OverlayVars
import com.varabyte.kobweb.silk.components.overlay.Tooltip
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.palette.background
import com.varabyte.kobweb.silk.theme.colors.palette.toPalette
import org.jetbrains.compose.web.css.*

val SideMenuStyle by ComponentStyle {
    val colorPalette = colorMode.toPalette()
    base {
        Modifier
            .fillMaxHeight()
            .width(33.percent)
            .padding(top = 1.cssRem, leftRight = 1.cssRem)
            .gap(1.5.cssRem)
            .backgroundColor(colorPalette.background)
            .borderRadius(topRight = 30.px, bottomRight = 30.px)
    }
    Breakpoint.ZERO {
        Modifier.width(50.percent)
    }
    Breakpoint.SM {
        Modifier.width(43.percent)
    }
    Breakpoint.MD {
        Modifier.width(30.percent)
    }
}

val SideMenuSlideInAnim by Keyframes {
    from {
        Modifier.translate((-100).percent)
    }

    to {
        Modifier.translateX(0.percent)
    }
}

enum class SideMenuState {
    CLOSED,
    OPEN,
    CLOSING;

    fun close() = when (this) {
        CLOSED -> CLOSED
        OPEN -> CLOSING
        CLOSING -> CLOSING
    }
}

@Composable
fun SideMenu(
    menuState: SideMenuState,
    selectedSectionId: String,
    onClick: (String) -> Unit = {},
    onCloseRequest: () -> Unit,
    onAnimationEnd: () -> Unit,
) {
    Overlay(
        modifier = Modifier
            .zIndex(2)
            .setVariable(OverlayVars.BackgroundColor, Colors.Transparent)
            .onClick { onCloseRequest() },
        contentAlignment = Alignment.CenterStart
    ) {
        key(menuState) {
            Column(
                SideMenuStyle.toModifier()
                    .animation(
                        SideMenuSlideInAnim.toAnimation(
                            duration = 200.ms,
                            timingFunction = if (menuState == SideMenuState.OPEN) AnimationTimingFunction.EaseOut else AnimationTimingFunction.EaseIn,
                            direction = if (menuState == SideMenuState.OPEN) AnimationDirection.Normal else AnimationDirection.Reverse,
                            fillMode = AnimationFillMode.Forwards
                        )
                    )
                    .onAnimationEnd { onAnimationEnd() }
                    .onClick { it.stopPropagation() },
                horizontalAlignment = Alignment.Start
            ) {
                IconButton(
                    modifier = Modifier.backgroundColor(Colors.Transparent),
                    onClick = {
                        onCloseRequest()
                    }
                ) {
                    CloseIcon(
                        modifier = Modifier
                    )
                }
                Column(
                    modifier = Modifier.gap(1.5.cssRem),
                    horizontalAlignment = Alignment.Start
                ) {
                    NavMenuLink(
                        href = "/",
                        text = "Home",
                        selected = "/" == selectedSectionId,
                        onClick = {
                            onClick(it)
                            onCloseRequest()
                        }
                    )
                    NavMenuLink(
                        href = "/about",
                        text = "About Us",
                        selected = "/about" == selectedSectionId,
                        onClick = {
                            onClick(it)
                            onCloseRequest()
                        }
                    )
                    NavMenuLink(
                        href = "/services",
                        text = "Services",
                        selected = "/services" == selectedSectionId,
                        onClick = {
                            onClick(it)
                            onCloseRequest()
                        }
                    )
                    NavMenuLink(
                        href = "/contact",
                        text = "Contact Us",
                        selected = "/contact" == selectedSectionId,
                        onClick = {
                            onClick(it)
                            onCloseRequest()
                        }
                    )
                }
            }
        }
    }
}


//val SideMenuLinkVariant by LinkStyle.addVariant(extraModifiers = {
//    TextStyle.toModifier(LabelLargeTextStyle)
//}) {
//    val colorPalette = colorMode.toPalette()
//    base {
//        Modifier
//            .textDecorationLine(TextDecorationLine.None)
//            .color(colorPalette.text.primary)
//    }
//    hover {
//        Modifier.color(colorPalette.brand.primary)
//    }
//    active {
//        Modifier.color(colorPalette.brand.primary.shifted(colorMode, 0.15f))
//    }
//}
//
//val ActiveSideMenuLinkVariant by LinkStyle.addVariant {
//    val colorPalette = colorMode.toPalette()
//    base {
//        Modifier.color(colorPalette.brand.primary)
//    }
//    hover {
//        Modifier
//    }
//    active {
//        Modifier
//    }
//}

@Composable
fun NavMenuLink(
    modifier: Modifier = Modifier,
    href: String,
    text: String,
    selected: Boolean,
    onClick: (String) -> Unit = {},
) {
    Link(
        path = href,
        text = text,
        variant = UndecoratedLinkVariant,
        modifier = NavBarLinkStyle
            .toModifier()
            .onClick { onClick(href) }
            .styleModifier {
                property("text-underline-offset", "10px")
                textDecorationLine(if (selected) TextDecorationLine.Underline else TextDecorationLine.None)
            }.color(if (selected) ThemeColor.DarkGreen.color else ThemeColor.Black.color)
            .fontSize(16.px)
            .then(modifier)
    )
}

@Composable
fun IconButton(
    modifier: Modifier = Modifier,
    tooltipText: String? = null,
    onClick: (() -> Unit)? = null,
    icon: @Composable () -> Unit,
) {
    Button(
        onClick = {
            onClick?.invoke()
        },
        modifier = modifier,
        //variant = IconButtonVariant
    ) {
        icon()
    }
    if (tooltipText != null) {
        Tooltip(
            target = ElementTarget.PreviousSibling,
            text = tooltipText
        )
    }
}