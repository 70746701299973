package com.cjrodriguezproductions.divine_motors.components.input_fields

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.stevdza.san.kotlinbs.models.InputSize
import com.stevdza.san.kotlinbs.models.InputValidation
import com.stevdza.san.kotlinbs.util.UniqueIdGenerator
import com.varabyte.kobweb.compose.css.BoxShadow
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.readOnly
import org.jetbrains.compose.web.attributes.required
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

/**
 * UI component used to capture user input or display information. It provides a text field
 * or other types of input fields where users can enter data.
 * @param id Unique identifier of the component.
 * @param label Input field label.
 * @param placeholder Input field placeholder.
 * @param type The type of the input.
 * @param size The size of the input.
 * @param disabled Whether this component should be disabled or not.
 * @param readOnly Whether to enable a read-only state of the component.
 * @param plainText Whether to remove all default styling from the component.
 * @param floating Whether to use a floating variant of the component.
 * @param required This field is particularly used inside a Form to indicate that
 * the form cannot be submitted if this field is empty.
 * @param validation Here you can customize the look of the input component, in two
 * different states: Success and Error.
 * @param value A default value that will be included inside the input.
 * @param onValueChange Lambda that will be triggered when a user types something.
 * @param onEnterClick Lambda that triggers when you press 'Enter' key on your keyword,
 * while the input component is focused.
 * inside the component.
 * */
@Composable
fun BSInputCustom(
    modifier: Modifier = Modifier,
    inputModifier: Modifier = Modifier,
    id: String? = null,
    label: String? = null,
    placeholder: String? = null,
    type: InputType<String> = InputType.Text,
    size: InputSize = InputSize.Default,
    disabled: Boolean = false,
    readOnly: Boolean = false,
    plainText: Boolean = false,
    floating: Boolean = false,
    required: Boolean = false,
    validation: MutableState<InputValidation>,
    value: String,
    onValueChange: (String) -> Unit,
    isInvalidField: () -> String = {""},
    onEnterClick: (() -> Unit)? = null
) {
    val randomId = remember {
        id ?: UniqueIdGenerator.generateUniqueId("input")
    }
    Div(
        attrs = modifier
            .thenIf(
                condition = floating,
                other = Modifier.classNames("form-floating")
            )
            .toAttrs()
    ) {
        if (!floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            )
            {
                Text(value = label)
            }
        }
        Input(
            attrs = inputModifier
                .styleModifier {
                    property("-webkit-box-shadow", "0 0 0px 1000px white inset")
                }
                .id(randomId)
                .classNames(if (plainText) "form-control-plaintext" else "form-control")
                .thenIf(
                    condition = validation.value.isValid,
                    other = Modifier.classNames("is-valid")
                )
                .thenIf(
                    condition = validation.value.isInvalid,
                    other = Modifier.classNames("is-invalid")
                )
                .thenIf(
                    condition = size != InputSize.Default,
                    other = Modifier.classNames(size.value)
                )
                .toAttrs {
                    value(value)
                    onInput {
                        onValueChange(it.value)
                        val inValidError = isInvalidField()
                        validation.value = validation.value.copy(
                            isValid = inValidError.isEmpty(),
                            isInvalid = inValidError.isNotEmpty(),
                            invalidFeedback = inValidError
                        )
                    }
                    onKeyUp {
                        if (onEnterClick != null) {
                            it.preventDefault()
                            if (it.key == "Enter") {
                                onEnterClick()
                            }
                        }
                    }
//                    onFocus{
//                        val inValidError = isInvalidField()
//                        validation.value = validation.value.copy(
//                            isValid = inValidError.isEmpty(),
//                            isInvalid = inValidError.isNotEmpty(),
//                            invalidFeedback = inValidError
//                        )
//                    }
                    if (!placeholder.isNullOrEmpty()) attr("placeholder", placeholder)
                    if (disabled) disabled()
                    if (readOnly) readOnly()
                    if (required) required()
                },
            type = type
        )
        if (validation.value.isValid) {
            Div(
                attrs = Modifier
                    .classNames("valid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.value.validFeedback)
            }
        }
        if (validation.value.isInvalid) {
            Div(
                attrs = Modifier//.padding(left = 20.px)
                    .classNames("invalid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.value.invalidFeedback)
            }
        }
        if (floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            )
            {
                Text(value = label)
            }
        }
    }
}