package com.cjrodriguezproductions.divine_motors.components.sections

import androidx.compose.runtime.Composable
import com.cjrodriguezproductions.divine_motors.components.navigation.DivineNavBarBrand
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

@Composable
fun Footer(modifier: Modifier = Modifier) {
    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()
    Column(
        modifier = modifier
            .fillMaxWidth()
            .backgroundColor(ThemeColor.DarkGreen.color)
            .color(ThemeColor.White.color)
            .padding(leftRight = 20.px).margin(top = 28.px)
    ) {
        val textAlign = when {
            breakpoint <= Breakpoint.SM -> TextAlign.Start
            breakpoint > Breakpoint.SM && breakpoint <= Breakpoint.MD -> TextAlign.End
            else -> TextAlign.Center
        }
        SimpleGrid(
            numColumns = numColumns(1, sm = 1, md = 2, lg = 4),
            modifier = Modifier
                .fillMaxWidth()
                .height(Height.MaxContent)
            //.padding(leftRight = 20.px)
        ) {
            Column(
                modifier = Modifier
                    //.fillMaxWidth()
                    //.height(100.px)
                    .margin(top = 20.px),
//                    .margin(top = 20.px)
//                    .padding(bottom = 20.px),

            ) {
                DivineNavBarBrand(isColored = false, shouldShowLogo = breakpoint >= Breakpoint.MD)
            }

            Column(
                modifier = Modifier
                    .margin(top = 20.px, bottom = 40.px)
                    .height(100.px)
                    .fillMaxWidth(),
                horizontalAlignment = if (breakpoint <= Breakpoint.MD) Alignment.End else Alignment.CenterHorizontally
            ) {
                //if (breakpoint <= Breakpoint.MD) TextAlign.End else TextAlign.Center
                H4(attrs = SpanTextStyle
                    .toModifier()
                    .textAlign(
                        when {
                            breakpoint <= Breakpoint.SM -> TextAlign.Start
                            breakpoint > Breakpoint.MD && breakpoint < Breakpoint.LG -> TextAlign.End
                            else -> textAlign
                        }
                    )
                    .fontWeight(FontWeight.Bold)
                    .color(ThemeColor.White.color)
                    .fillMaxWidth()
                    .toAttrs { }
                ) {
                    Text("Company")
                }
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.SM -> TextAlign.Start
                                breakpoint > Breakpoint.MD && breakpoint < Breakpoint.LG -> TextAlign.End
                                else -> textAlign
                            }
                        ).onClick {
                            ctx.router.navigateTo("/about")
                        }
                        .fillMaxWidth().cursor(Cursor.Pointer),
                    text = "About Us",
                )
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.SM -> TextAlign.Start
                                breakpoint > Breakpoint.MD && breakpoint < Breakpoint.LG -> TextAlign.End
                                else -> textAlign
                            }
                        )
                        .fillMaxWidth().onClick {
                            ctx.router.navigateTo("/contact")
                        }.cursor(Cursor.Pointer),
                    text = "Contact Us",
                )
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.SM -> TextAlign.Start
                                breakpoint > Breakpoint.MD && breakpoint < Breakpoint.LG -> TextAlign.End
                                else -> textAlign
                            }
                        ).onClick {
                            window.location.href = "https://www.termsfeed.com/live/0e95b021-6723-4fae-a8bf-b939bdfa5d73"
                        }.cursor(Cursor.Pointer)
                        .fillMaxWidth(),
                    text = "Terms & Conditions",
                )
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.SM -> TextAlign.Start
                                breakpoint > Breakpoint.MD && breakpoint < Breakpoint.LG -> TextAlign.End
                                else -> textAlign
                            }
                        )
                        .fillMaxWidth().onClick {
                            window.location.href = "https://www.termsfeed.com/live/0e95b021-6723-4fae-a8bf-b939bdfa5d73"
                        }.cursor(Cursor.Pointer),
                    text = "Privacy Policy",
                )
            }

            Column(
                modifier = Modifier
                    //.margin(top = 20.px, left = if (breakpoint <= Breakpoint.SM) 0.px else 20.px)
                    .margin(top = 20.px)
                    .height(Height.FitContent)
                    //.height(100.px)
                    .fillMaxWidth(),
                //.padding(bottom = 20.px),
                horizontalAlignment = if (breakpoint <= Breakpoint.MD) Alignment.Start else Alignment.CenterHorizontally
                //horizontalAlignment = Alignment.End
            ) {
                H4(attrs = SpanTextStyle
                    .toModifier()
                    .textAlign(
                        when {
                            breakpoint <= Breakpoint.MD -> TextAlign.Start
                            breakpoint > Breakpoint.MD -> TextAlign.Center
                            else -> textAlign
                        }
                    )
                    .color(ThemeColor.White.color)
                    .fontWeight(FontWeight.Bold)
                    .fillMaxWidth()
                    .toAttrs { }) {
                    Text("Resources")
                }
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.MD -> TextAlign.Start
                                breakpoint > Breakpoint.MD -> TextAlign.Center
                                else -> textAlign
                            }
                        ).onClick {
                            ctx.router.navigateTo("/services")
                        }.fillMaxWidth().cursor(Cursor.Pointer),
                    text = "Services",
                )
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.MD -> TextAlign.Start
                                breakpoint > Breakpoint.MD -> TextAlign.Center
                                else -> textAlign
                            }
                        ).onClick {
                            ctx.router.navigateTo("/?id=#FAQ")
                        }.fillMaxWidth().cursor(Cursor.Pointer),
                    text = "FAQs",
                )
            }

            Column(
                modifier = Modifier
                    .height(Height.FitContent)
                    .margin(top = 20.px)
                    .padding(bottom = 20.px),
                horizontalAlignment = Alignment.End
            ) {
                H4(attrs = SpanTextStyle
                    .toModifier()
                    .textAlign(
                        when {
                            breakpoint <= Breakpoint.SM -> TextAlign.Start
                            breakpoint > Breakpoint.SM && breakpoint <= Breakpoint.MD -> TextAlign.End
                            breakpoint > Breakpoint.MD -> TextAlign.End
                            else -> textAlign
                        }
                    )
                    .color(ThemeColor.White.color)
                    .fontWeight(FontWeight.Bold)
                    .fillMaxWidth()
                    .toAttrs { }
                ) {
                    Text("Contact Information")
                }
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.SM -> TextAlign.Start
                                breakpoint > Breakpoint.SM && breakpoint <= Breakpoint.MD -> TextAlign.End
                                breakpoint > Breakpoint.MD -> TextAlign.End
                                else -> textAlign
                            }
                        )
                        .fillMaxWidth().onClick {
                            window.location.href = "tel:+234 90000000"
                        }.cursor(Cursor.Pointer),
                    text = "+234 90000000",
                )
                SpanText(
                    modifier = Modifier
                        .textAlign(
                            when {
                                breakpoint <= Breakpoint.SM -> TextAlign.Start
                                breakpoint > Breakpoint.SM && breakpoint <= Breakpoint.MD -> TextAlign.End
                                breakpoint > Breakpoint.MD -> TextAlign.End
                                else -> textAlign
                            }
                        )
                        .fillMaxWidth().onClick {
                            window.location.href = "mailto:contact@divinemotorsltd.com"
                        }.cursor(Cursor.Pointer),
                    text = "contact@divinemotorsltd.com",
                )
            }
        }

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(
//                    left = 20.px,
//                    right = 20.px,
                    bottom = 20.px
                )
        ) {
            HorizontalDivider(modifier = Modifier.fillMaxWidth())
            Text("© 2024 Divine Motors. All Rights Reserved")
        }
    }
}