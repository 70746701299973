package com.cjrodriguezproductions.divine_motors.components.sections

import androidx.compose.runtime.*
import com.cjrodriguezproductions.divine_motors.components.navigation.*
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.stevdza.san.kotlinbs.components.BSNavBar
import com.varabyte.kobweb.compose.css.Height
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.HamburgerIcon
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.style.breakpoint.displayUntil
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Text

@Composable
fun NavHeader(
    onSelectItem: (String)-> Unit,
    selectedId: String,
) {
        NavLinks(
            onSelectItem = onSelectItem,
            selectedId = selectedId,
            modifier = Modifier.displayIfAtLeast(Breakpoint.MD)
        )
        SideMenuSmall(
            onSelectItem = onSelectItem,
            selectedId = selectedId,
            modifier = Modifier.displayUntil(Breakpoint.MD)
        )
}

@Composable
private fun NavLinks(
    onSelectItem: (String)-> Unit,
    selectedId: String,
    modifier: Modifier = Modifier
) {
    Row(
        modifier = modifier
            .height(Height.FitContent)
            .fillMaxWidth()
            .padding(topBottom = 10.px, leftRight = 20.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        DivineNavBarBrand()

        Row(
            modifier = Modifier
                .fillMaxWidth(50.percent)
                .fillMaxHeight(),
            horizontalArrangement = Arrangement.SpaceAround,
            verticalAlignment = Alignment.CenterVertically
        ) {
            NavMenuLink(
                modifier = Modifier
                    .padding(0.px, 25.px),
                href = "/",
                text = "Home",
                selected = "/" == selectedId,
                onClick = { onSelectItem.invoke(it) }
            )
            NavMenuLink(
                modifier = Modifier
                    .padding(0.px, 25.px),
                href = "/about",
                text = "About Us",
                selected = "/about" == selectedId
            ){ onSelectItem.invoke(it) }
            NavMenuLink(
                modifier = Modifier
                    .padding(0.px, 25.px),
                href = "/services",
                text = "Services",
                selected = "/services" == selectedId
            ){ onSelectItem.invoke(it) }
            NavMenuLink(
                modifier = Modifier
                    .padding(0.px, 25.px),
                href = "/contact",
                text = "Contact Us",
                selected = "/contact" == selectedId
            ){ onSelectItem.invoke(it) }
        }

        val ctx = rememberPageContext()
        Button(
            onClick = {
                onSelectItem.invoke("/contact")
                ctx.router.navigateTo("/contact")
            },
            modifier = Modifier
                .borderRadius(r = 10.px)
                .color(ThemeColor.White.color)
                .height(50.px),
        ) {
            Text("Enquire")
        }
    }
}

@Composable
private fun SideMenuSmall(
    onSelectItem: (String)-> Unit,
    selectedId: String,
    modifier: Modifier = Modifier
){
    Row(
        modifier = modifier
            .fillMaxWidth()
            .displayUntil(Breakpoint.LG),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        var sideMenuState by remember { mutableStateOf(SideMenuState.CLOSED) }
        if (sideMenuState != SideMenuState.CLOSED) {
            SideMenu(
                menuState = sideMenuState,
                selectedSectionId = selectedId,
                onClick = {
                          onSelectItem.invoke(it)
                },
                onCloseRequest = { sideMenuState = sideMenuState.close() },
                onAnimationEnd = {
                    if (sideMenuState == SideMenuState.CLOSING) {
                        sideMenuState = SideMenuState.CLOSED
                    }
                }
            )
        }
        Box(
            modifier = Modifier.flex(1), contentAlignment = Alignment.CenterStart) {
            IconButton(
                modifier = Modifier
                    .margin(top = 5.px)
                    .backgroundColor(Colors.Transparent).color(ThemeColor.DarkGreen.color),
                onClick = { sideMenuState = SideMenuState.OPEN }
            ) {
                HamburgerIcon(modifier = Modifier.size(20.px))
            }
        }
        Box(
            modifier = Modifier.flex(1),
            contentAlignment = Alignment.Center) {
            DivineNavBarBrand(
                modifier = Modifier
                .align(Alignment.CenterEnd),
                //.margin(right = 20.px),
                shouldShowLogo = false
            )
        }
    }
}