package com.cjrodriguezproductions.divine_motors.components.collapsable_faq

import androidx.compose.runtime.*
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.stevdza.san.kotlinbs.components.BSCollapse
import com.stevdza.san.kotlinbs.components.showCollapse
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaMinus
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

@Composable
fun CollapsibleFAQ(
    modifier: Modifier = Modifier,
    color: CSSColorValue,
    id: String,
    title: String,
    subtitle: String,
    breakpoint: Breakpoint
){
    var isExpanded by remember { mutableStateOf(false) }
    Surface(
        modifier = modifier
        .backgroundColor(color = color)
        .borderRadius(10.px)
        .showCollapse(id = id)
        .onClick { isExpanded = !isExpanded }
        .fillMaxWidth(if (breakpoint != Breakpoint.SM) 60.percent else 100.percent)
        .cursor(Cursor.Pointer)
    ){
        Column(modifier = Modifier.fillMaxWidth()) {
            Row(
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth().padding(10.px)
            ) {
                H4(attrs = SpanTextStyle
                    .toModifier()
                    .fontSize(if (breakpoint == Breakpoint.SM) 18.px else 20.px)
                    .margin(left = 10.px)
                    .fillMaxWidth()
                    .toAttrs()) { Text(title) }

                Box(
                    modifier = Modifier
                        .size(24.px)
                        .borderRadius(50.percent)
                        .border(width = 1.px, style = LineStyle.Solid),
                    contentAlignment = Alignment.Center
                ) {
                    if (isExpanded){
                        FaMinus(
                            size = IconSize.X1
                        )
                    } else {
                        FaPlus(
                            size = IconSize.X1
                        )
                    }
                }
            }
            BSCollapse(
                id = id,
            ) {
                Column(
                    modifier = Modifier.padding(10.px).margin(left = 10.px),
                    verticalArrangement = Arrangement.Center
                ) {
                    SpanText(
                        text = subtitle
                    )
                }
            }
        }
    }
}