package com.cjrodriguezproductions.divine_motors.pages

import androidx.compose.runtime.*
import com.cjrodriguezproductions.divine_motors.components.ProgressErrorSnackBar
import com.cjrodriguezproductions.divine_motors.components.input_fields.InputCustomField
import com.cjrodriguezproductions.divine_motors.components.layouts.DefaultPageLayout
import com.stevdza.san.kotlinbs.models.InputValidation
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import kotlin.js.Promise

@Page()
@Composable
fun ContactPage() {
    val selectedSectionId = remember { mutableStateOf("/contact") }
    val emailRegex = "^[A-Za-z](.*)([@]{1})(.{1,})(\\.)(.{1,})".toRegex()
    val phoneRegex = "^[+|(|\\d]?[\\d|(|\\s|]+[)|\\s]+\\d+[-|\\s]+\\d+".toRegex()
    val ctx = rememberPageContext()
    DefaultPageLayout(
        selectedId = selectedSectionId.value,
        onSelectItem = { selectedSectionId.value = it }
    ) {
        val contactId = ctx.route.params["id"]
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight()
                .padding(leftRight = 20.px)
        ) {
            H1(attrs = SpanTextStyle
                .toModifier()
                .textAlign(TextAlign.Center)
                .color(ThemeColor.DarkGreen.color)
                .fillMaxWidth()
                .padding(top = 40.px)
                .toAttrs { }
            ) {
                Text("Contact Us")
            }

            SimpleGrid(
                numColumns = numColumns(1, sm = 1, lg = 2),
                modifier = Modifier
                    .fillMaxWidth()
                    .fillMaxHeight()
                    .margin(
                        //leftRight = 40.px,
                        topBottom = 40.px
                    )//.padding(leftRight = 40.px)
                    .gap(40.px)
            ) {
                Column(
                    modifier = Modifier
                        .backgroundColor(ThemeColor.DarkGreen.color)
                        .color(ThemeColor.White.color)
                        .height(100.percent)
                        .padding(
                            //left = 20.px,
                            //right = 40.px,
                        )
                        .margin(
                            //left = 20.px,
                            bottom = 40.px,
                            //right = 40.px
                        )
                        .borderRadius(10.px),
                ) {
                    H4(attrs = SpanTextStyle
                        .toModifier()
                        .fontWeight(FontWeight.Bold)
                        .fillMaxWidth()
                        .color(ThemeColor.White.color)
                        .margin(bottom = 24.px, top = 24.px)
                        .padding(left = 20.px)
                        .toAttrs { }
                    ) {
                        Text("Contact Information")
                    }

                    H5  (attrs = SpanTextStyle
                        .toModifier()
                        .fontWeight(FontWeight.Bold)
                        .fillMaxWidth()
                        .color(ThemeColor.White.color)
                        .padding(left = 20.px)
                        .margin(bottom = 24.px)
                        .toAttrs { }
                    ) {
                        Text("Let's get in touch")
                    }

                    Row(
                        horizontalArrangement = Arrangement.spacedBy(20.px),
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .margin(bottom = 30.px)
                            .flexWrap(FlexWrap.Wrap)
                            .padding(left = 20.px)
                            .onClick {
                            window.location.href = "tel:+23499000000000"
                        }.cursor(Cursor.Pointer)
                    ) {
                        Image(src = "phone_icon_final.svg")
                        SpanText(
                            text = "+234 90000000")
//                        H4 {
//                            Text(value = "+234 9000000000")
//                        }
                    }

                    Row(
                        horizontalArrangement = Arrangement.spacedBy(20.px),
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .margin(bottom = 30.px)
                            .flexWrap(FlexWrap.Wrap)
                            .padding(left = 20.px)
                            //.fontSize(24.px)
                            .onClick {
                            window.location.href = "mailto:contact@divinemotorsltd.com"
                        }.cursor(Cursor.Pointer)
                    ) {
                        Image(src = "email_icon_final.svg")
//                        H4 {
//                            Text(value = "contact@divinemotorsltd.com")
//                        }
                        SpanText(
                            modifier = Modifier
                                .overflowWrap(OverflowWrap.BreakWord),
                            text = "contact@divinemotorsltd.com")
                    }

                    Row(
                        horizontalArrangement = Arrangement.spacedBy(20.px),
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .margin(bottom = 30.px)
                            .flexWrap(FlexWrap.Wrap)
                            .padding(left = 20.px)
                            //.fontSize(24.px)
                    ) {
                        Image(src = "location_icon_final.svg")
//                        H4 {
//                            Text(value = "Port Harcourt, Rivers State. Nigeria")
//                        }
                        SpanText(
                            modifier = Modifier.overflowWrap(OverflowWrap.Anywhere),
                            text = "Port Harcourt, Rivers State. Nigeria")
                    }
                }

                var firstName by remember { mutableStateOf("") }
                var lastName by remember { mutableStateOf("") }
                var email by remember { mutableStateOf("") }
                var phoneNo by remember { mutableStateOf("") }
                var message by remember { mutableStateOf(contactId?.let {
                    when (it) {
                        "Hilux" -> "I would like to enquire about the Hilux"
                        "Coaster" -> "I would like to enquire about the Coaster"
                        "Hummer" -> "I would like to enquire about the Hummer"
                        else -> ""
                    }
                }?:"") }
//                var message by remember { mutableStateOf("") }
                val firstNameValidation = remember { mutableStateOf(InputValidation(validFeedback = "")) }
                val lastNameValidation = remember { mutableStateOf(InputValidation(validFeedback = "")) }
                val messageValidation = remember { mutableStateOf(InputValidation(validFeedback = "")) }
                val emailValidation = remember { mutableStateOf( InputValidation(validFeedback = "")) }
                val phoneValidation = remember { mutableStateOf( InputValidation(validFeedback = "")) }
                //val breakpoint = rememberBreakpoint()
                Column(
                    modifier = Modifier
                        .color(ThemeColor.Black.color)
                        //.padding(
                            //left = 20.px,
                            //right = 40.px
                        //)
                        .fillMaxWidth(),
                    verticalArrangement = Arrangement.spacedBy(40.px)
                ) {
//                    if (breakpoint <= Breakpoint.MD){
//                        Column(Modifier.height(0.px).fillMaxWidth()) {}
//                    }
                    SimpleGrid(
                        numColumns = numColumns(1, sm = 1, lg = 2),
                        modifier = Modifier
                            .fillMaxWidth()
                            .gap(40.px)
                            .margin(bottom = 10.px)){
                        InputCustomField(
                            value = firstName,
                            modifier = Modifier.fillMaxWidth(),
                            label = "First name",
                            onValueChanged = { firstName = it },
                            isInvalidField = { if (firstName.isEmpty()) "Please enter first name" else "" },
                            placeholder = "First Name",
                            inputValidation = firstNameValidation
                        )
                        InputCustomField(
                            value = lastName,
                            modifier = Modifier
                                .fillMaxWidth(),
                            label = "Last name",
                            isInvalidField = { if (lastName.isEmpty()) "Please enter last name" else "" },
                            onValueChanged = { lastName = it },
                            placeholder = "Last Name",
                            inputValidation = lastNameValidation
                        )
                    }
//                    Row(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .margin(bottom = 10.px),
//                        horizontalArrangement = Arrangement.SpaceBetween
//                    ) {
//                        InputCustomField(
//                            value = firstName,
//                            modifier = Modifier
//                                .fillMaxWidth(),
//                            label = "First name",
//                            onValueChanged = { firstName = it },
//                            isInvalidField = { if (firstName.isEmpty()) "Please enter first name" else "" },
//                            placeholder = "First Name",
//                            inputValidation = firstNameValidation
//                        )
//                        Div(
//                            attrs = SpanTextStyle.toModifier()
//                            .width(40.px)
//                            .toAttrs { }
//                        )
//                        InputCustomField(
//                            value = lastName,
//                            modifier = Modifier
//                                .fillMaxWidth(),
//                            label = "Last name",
//                            isInvalidField = { if (lastName.isEmpty()) "Please enter last name" else "" },
//                            onValueChanged = { lastName = it },
//                            placeholder = "Last Name",
//                            inputValidation = lastNameValidation
//                        )
//                    }
                    SimpleGrid(
                        numColumns = numColumns(1, sm = 1, lg = 2),
                        modifier = Modifier
                            .fillMaxWidth().gap(40.px)
                            .margin(bottom = 10.px)){
                        InputCustomField(
                            value = email,
                            modifier = Modifier
                                .fillMaxWidth(),
                            label = "Email",
                            onValueChanged = { email = it },
                            isInvalidField = { isEmailInvalid(emailRegex, email) },
                            placeholder = "Email",
                            inputValidation = emailValidation
                        )
                        InputCustomField(
                            value = phoneNo,
                            modifier = Modifier
                                //.fillMaxWidth(95.percent),
                                .fillMaxWidth(),
                            label = "Phone number",
                            isInvalidField = { if (phoneNo.isEmpty()) "Please enter phone no" else "" },
                            onValueChanged = { phoneNo = it },
                            placeholder = "Phone number",
                            inputValidation = phoneValidation
                        )
                    }
//                    Row(
//                        modifier = Modifier
//                            .fillMaxWidth()
//                            .margin(bottom = 10.px),
//                        horizontalArrangement = Arrangement.SpaceBetween
//                    ) {
//                        InputCustomField(
//                            value = email,
//                            modifier = Modifier
//                                .fillMaxWidth(),
//                            label = "Email",
//                            onValueChanged = { email = it },
//                            isInvalidField = { isEmailInvalid(emailRegex, email) },
//                            placeholder = "Email",
//                            inputValidation = emailValidation
//                        )
//                        Div(
//                            attrs = SpanTextStyle.toModifier()
//                                .width(40.px)
//                                .toAttrs { }
//                        )
//                        InputCustomField(
//                            value = phoneNo,
//                            modifier = Modifier
//                                //.fillMaxWidth(95.percent),
//                                .fillMaxWidth(),
//                            label = "Phone number",
//                            isInvalidField = { if (phoneNo.isEmpty()) "Please enter phone no" else "" },
//                            onValueChanged = { phoneNo = it },
//                            placeholder = "Phone number",
//                            inputValidation = phoneValidation
//                        )
//                    }
                    InputCustomField(
                        value = message,
                        label = "Message",
                        onValueChanged = { message = it },
                        isInvalidField = {if (message.isEmpty()) "Please enter message" else ""},
                        isMultiLine = true,
                        placeholder = "Message",
                        inputValidation = messageValidation,
                        modifier = Modifier.fillMaxWidth().fillMaxHeight(),
                        columnModifier = Modifier.fillMaxHeight()
                    )
                    var finishedEmailSendResult by remember { mutableStateOf("") }
                    var shouldSnackBar by remember { mutableStateOf(false) }
                    if (shouldSnackBar){
                        ProgressErrorSnackBar(
                            modifier = Modifier
                                .fillMaxWidth()
                                .height(60.px),
                            shouldProgress = shouldSnackBar,
                            errorMessage = finishedEmailSendResult
                        )
//                        BSAlert(
//                            modifier = Modifier.fillMaxWidth(),
//                            dismissible = true,
//                            message = finishedEmailSendResult
//                        )
                    }
                    LaunchedEffect(shouldSnackBar){
                        delay(3000)
                        shouldSnackBar = false
                    }
                    Button(
                        modifier = Modifier
                        .padding(10.px)
                        .borderRadius(10.px)
                        .backgroundColor(ThemeColor.DarkGreen.color)
                        .align(Alignment.End)
                        .margin(
                            top = 20.px
                        )
                        .color(ThemeColor.White.color)
                        //.fontSize(30.px)
                        .height(Height.FitContent),
                        enabled = isSendButtonEnabled(
                            firstNameInputValidation = firstNameValidation.value,
                            lastNameInputValidation = lastNameValidation.value,
                            emailInputValidation = emailValidation.value,
                            phoneNoValidation = phoneValidation.value,
                            messageInputValidation = messageValidation.value
                        ),
                        onClick = {
                            val formattedMessage = """
                                <p>Message: ${message.trim()}</p>
                                <br>
                                <p><strong>Full Name:</strong> ${firstName.trim()} ${lastName.trim()}</p>
                                <br>
                                <p><strong>Phone No:</strong> ${phoneNo.trim()}</p>
                                <br>
                                <p>Email:</strong> $email</p>
                            """.trimIndent()

                            val promise = sendEmail(
                                firstName = firstName.trim(),
                                email = email.trim(),
                                message = formattedMessage,
                                subject = "${firstName.trim()} ${lastName.trim()} wants to contact Divine Motors",
                                key = AppGlobals.getValue("SM_KY"),
                                toEmail = AppGlobals.getValue("TO_EMAIL")
                            )
                            shouldSnackBar = true
                            promise.then {
                                val isError = it != "OK"
                                finishedEmailSendResult = if (isError) "Error sending email" else "Emails sent successfully"
                            }
                            firstName = ""
                            lastName = ""
                            email = ""
                            message = ""
                            phoneNo = ""
                        }
                    ) {
                        Text("Send Message")
                    }
                }
            }
        }
    }
}

private fun isEmailInvalid(regex: Regex, email: String): String{
    return when{
        email.isEmpty() -> "Please enter email"
        !email.matches(regex) -> "Please Enter valid email"
        else -> ""
    }
}

private fun isPhoneInvalid(regex: Regex, phoneNo: String): String{
    return when{
        phoneNo.isEmpty() -> "Please enter phone"
        !phoneNo.matches(regex) -> "Please Enter valid phone number"
        else -> ""
    }
}
private fun isSendButtonEnabled(
    firstNameInputValidation: InputValidation,
    lastNameInputValidation: InputValidation,
    emailInputValidation: InputValidation,
    messageInputValidation: InputValidation,
    phoneNoValidation: InputValidation,
): Boolean{
    return firstNameInputValidation.isValid && emailInputValidation.isValid
            && messageInputValidation.isValid && lastNameInputValidation.isValid
            && phoneNoValidation.isValid
}

private fun sendEmail(
    firstName: String,
    email: String,
    message: String,
    subject: String,
    key: String,
    toEmail: String
):Promise<String> {
    return js("""
        Email.send({
            SecureToken: key,
            To: toEmail,
            From: email,
            Subject: subject,
            Body: message
        }).then(function(message) {
            return message;
        });
    """)
}

//private fun sendEmail(
//    firstName: String,
//    email: String,
//    message: String,
//    subject: String,
//    key: String
//):Promise<dynamic> {
//    return js("""
//        Email.send({
//            SecureToken: key,
//            To: 'ngozieuchariaoffu@gmail.com',
//            From: email,
//            Subject: subject,
//            Body: message
//        }).then(function(message) {
//            alert(message);
//            (message === 'OK')
//        });
//    """)
//}




