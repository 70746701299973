package com.cjrodriguezproductions.divine_motors.components.input_fields

import androidx.compose.runtime.*
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

@Composable
fun InfiniteScrollingImageCarouselStage(imagesList: List<String>) {
    var currentIndex by remember { mutableStateOf(0) }
    var isDragging by remember { mutableStateOf(false) }
    var startX by remember { mutableStateOf(0f) }
    var currentX by remember { mutableStateOf(0f) }
    var dragOffset by remember { mutableStateOf(0f) }
    val ctx = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    LaunchedEffect(Unit) {
        // Auto_play functionality
        while (true) {
            delay(5000)
            if (!isDragging) {
                //currentIndex = if (currentIndex + 1 <= imagesList.size) currentIndex+1 else extendedImagesList.size-1
                //currentIndex++
                currentIndex = (currentIndex + 1) % imagesList.size
                dragOffset = 0f
            }
        }
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            //.height(800.px)
            .height(94.vh)
            .overflow(Overflow.Hidden)
            .touchAction(TouchAction.PanY)
    ) {
        Row(
            modifier = Modifier
                .width(imagesList.size.times(100).percent)
                .transition(
                    Transition.of(
                        property = "transform",
                        duration = 300.ms,
                        timingFunction = TransitionTimingFunction.EaseInOut
                    )
                ).transform {
                    translateX((-currentIndex * 20).percent)
                }
                .onMouseDown {
                    isDragging = true
                    startX = it.clientX.toFloat()
                    currentX = startX
                }
                .onMouseMove {
                    if (isDragging) {
                        currentX = it.clientX.toFloat()
                        val diff = currentX - startX
                        dragOffset = diff
                        // Implement drag logic here
                    }
                }
                .onMouseUp {
                    // Implement slide change logic based on drag here
                    val diff = currentX - startX
                    if (diff < -50) { // Swipe left
                        currentIndex = (currentIndex + 1) % imagesList.size
                    } else if (diff > 50) { // Swipe right
                        currentIndex = (currentIndex - 1 + imagesList.size) % imagesList.size
                    }
                    // Reset drag values
                    startX = 0f
                    currentX = 0f
                    dragOffset = 0f

                    isDragging = false
                }.onMouseLeave {
                    if (isDragging) {
                        isDragging = false
                        dragOffset = 0f
                    }
                }
                .cursor(if (isDragging) Cursor.Grabbing else Cursor.Grab),
            horizontalArrangement = Arrangement.Center
        ) {
            imagesList.forEach { imageUrl ->
                Box(
                    modifier = Modifier.zIndex(0)
                        .width(100.percent)
                        .height(94.vh)
                        .overflow(Overflow.Hidden)
                        //.height(800.px)
                ) {
                    Image(
                        src = imageUrl,
                        modifier = Modifier
                            .fillMaxWidth()
                            .fillMaxHeight()
                            //.width(100.percent)
                            //.height(auto)
                            //.height(800.px)
                            .objectFit(ObjectFit.Cover)
                            .pointerEvents(PointerEvents.None)
                            //.then(OppositeHoverImageStyle.toModifier())
                    )

                    Surface(
                        modifier = Modifier
                            .width(100.percent)
                            .fillMaxHeight()
                            //.height(800.px)
                            .backgroundColor(ThemeColor.LightGreenTransparent.color)
                            .backgroundBlendMode(BackgroundBlendMode.Overlay)
                    ) {}
                }
            }
        }

        // Dots
        Row(
            modifier = Modifier
                .align(Alignment.BottomCenter)
                .zIndex(4)
                .margin(bottom = 40.px),
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.Bottom
        ) {
            imagesList.forEachIndexed { index, _ ->
                Box(
                    modifier = Modifier
                        .size(10.px)
                        .borderRadius(50.percent)
                        .backgroundColor(if (index == currentIndex) ThemeColor.White.color else ThemeColor.OverlayGreen.color)
                        .margin(leftRight = 5.px)
                        .cursor(Cursor.Pointer)
                        .onClick { currentIndex = index }
                )
            }
        }

        Column(
            Modifier
                .margin(leftRight = 20.px)
                .alignSelf(com.varabyte.kobweb.compose.css.AlignSelf.Center)
                .color(ThemeColor.White.color)
                .zIndex(1),
            verticalArrangement = Arrangement.Center
        ) {
            H1(attrs = SpanTextStyle
                .toModifier()
                .margin(bottom = 20.px)
                .color(ThemeColor.White.color)
                .fontSize(if (breakpoint == Breakpoint.SM) 24.px else 40.px)
                .fontWeight(FontWeight.Bold)
                .toAttrs { }
            ) {
                Text("Drive Your Dream Car Today!")
            }
            H4(attrs = SpanTextStyle
                .toModifier()
                .color(ThemeColor.White.color).toAttrs()
            ) {
                Text("Experience the freedom of driving a new car" +
                        " with affordable lease options tailored to you.")
            }

            Button(
                modifier = Modifier
                    .color(ThemeColor.White.color)
                    .borderRadius(r = 10.px)
                    .height(50.px)
                    .margin(top = 20.px),
                onClick = {
                    ctx.router.navigateTo("/about")
                }) {
                Text("Learn More")
            }
        }
    }

//    fun extendListIfNeeded() {
//        val itemsAfterCurrent = extendedImagesList.size - currentIndex - 1
//        if (itemsAfterCurrent < threshold) {
//            val itemsToAdd = imagesList.take(threshold - itemsAfterCurrent)
//            extendedImagesList.addAll(itemsToAdd)
//        }
//
//        if (currentIndex < threshold) {
//            val itemsToAdd = imagesList.takeLast(threshold - currentIndex)
//            extendedImagesList.addAll(0, itemsToAdd)
//            currentIndex += itemsToAdd.size
//        }
//    }
//
//    fun trimListIfNeeded() {
//        // Remove excess items from the beginning
//        val excessStart = currentIndex - (threshold * maxDuplicates)
//        if (excessStart > 0) {
//            extendedImagesList = extendedImagesList.drop(excessStart).toMutableList()
//            currentIndex -= excessStart
//        }
//
//        // Remove excess items from the end
//        val excessEnd = extendedImagesList.size - currentIndex - 1 - (threshold * maxDuplicates)
//        if (excessEnd > 0) {
//            extendedImagesList = extendedImagesList.dropLast(excessEnd).toMutableList()
//        }
//    }
}

//val SlideNextIndex by Keyframes {
////    to {
////        val index = ("m").toIntOrNull()?:0
////        Modifier.translateX(-(index * 20).percent)
////    }
//    to {
//        Modifier.transition(
//            Transition.of(
//                property = "transform",
//                duration = 300.ms,
//                timingFunction = AnimationTimingFunction.Linear
//            )
//        ).transform {
//            val index = ("--m").toIntOrNull()?:0
//            translateX(-(index * 20).percent)
//        }
//    }
//}

val OppositeHoverImageStyle = CssStyle {
    base {
        Modifier
            .transition(CSSTransition(property = "transform", duration = 300.ms))
            .transform { scale(1.0) }
    }
    hover {
        Modifier
            .transition(CSSTransition(property = "transform", duration = 300.ms))
            .transform { scale(0.8) }
    }
}