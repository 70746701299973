package com.cjrodriguezproductions.divine_motors.components.input_fields

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.stevdza.san.kotlinbs.models.InputValidation
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px

@Composable
fun InputCustomField(
    value: String,
    label: String,
    inputValidation: MutableState<InputValidation> = remember { mutableStateOf(InputValidation(validFeedback = "")) },
    onValueChanged: (String) -> Unit,
    isInvalidField: () -> String = {""},
    placeholder: String,
    isMultiLine: Boolean = false,
    modifier: Modifier = Modifier,
    columnModifier: Modifier = Modifier,
){
    Column(
        modifier = columnModifier.fillMaxWidth()
    ) {
        SpanText(label, modifier = Modifier.fontWeight(FontWeight.Bold).margin(bottom = 4.px))
        if (isMultiLine){
            BSTextAreaCustom(
                value = value,
                onValueChange = onValueChanged,
                placeholder = placeholder,
                validation = inputValidation,
                isInvalidField = isInvalidField,
                size = com.stevdza.san.kotlinbs.models.InputSize.Small,
                modifier =  Modifier
                    .outline(
                        width = 2.px,
                        style = LineStyle.Solid,
                        color = ThemeColor.Grey.color
                    )
                    .borderRadius(10.px)
                    .height(200.px)
                    .transition(Transition.of(property = "border", duration = 300.ms)).then(modifier)
            )
        } else {
            BSInputCustom(
                value = value,
                validation = inputValidation,
                placeholder = placeholder,
                isInvalidField = isInvalidField,
                size = com.stevdza.san.kotlinbs.models.InputSize.Small,
                //size = com.stevdza.san.kotlinbs.models.InputSize.valueOf("20.px"),
                onValueChange = onValueChanged,
                modifier = Modifier
                    .outline(
                        width = 2.px,
                        style = LineStyle.Solid,
                        color = ThemeColor.Grey.color
                    )
                    .borderRadius(10.px)
                    //.fontSize(40.px)
                    .transition(Transition.of(property = "border", duration = 300.ms))
                    .height(50.px)
                    .then(modifier),
                inputModifier = Modifier.height(50.px)
            )
//            TextInput(
//                text = value,
//                placeholder = placeholder,
//                size = InputSize.LG,
//                onTextChange = onValueChanged,
//                modifier = Modifier
//                    .padding(topBottom = 10.px, leftRight = 10.px)
//                    .outline(
//                        width = 2.px,
//                        style = LineStyle.Solid,
//                        color = ThemeColor.Grey.color
//                    ).borderRadius(10.px)
//                    .fontSize(40.px)
//                    .height(80.px)
//                    .transition(Transition.of(property = "border", duration = 300.ms))
//                    .then(modifier)
//            )
        }
    }
}