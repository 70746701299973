package com.cjrodriguezproductions.divine_motors.components.layouts

import androidx.compose.runtime.Composable
import com.cjrodriguezproductions.divine_motors.components.sections.Footer
import com.cjrodriguezproductions.divine_motors.components.sections.NavHeader
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.Position

@Composable
fun DefaultPageLayout(
    onSelectItem: (String)-> Unit,
    selectedId: String,
    content: @Composable () -> Unit,
) {
    Column(
        modifier = Modifier.fillMaxSize()
            .overflow(Overflow.Hidden)
            .position(Position.Relative),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        NavHeader(
            selectedId = selectedId,
            onSelectItem = { onSelectItem.invoke(it) }
        )
        content()
        Footer()
    }
}