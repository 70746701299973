package com.cjrodriguezproductions.divine_motors.components.reasons_card

import androidx.compose.runtime.Composable
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.shapes.Circle
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.Text

@Composable
fun ReasonCard(
    modifier: Modifier = Modifier,
    imageUrl: String,
    title: String,
    subTitle: String,
    breakpoint: Breakpoint
) {
    val titleFontSize = if (breakpoint == Breakpoint.SM) 1.2.cssRem else 1.4.cssRem
    val subtitleFontSize = if (breakpoint == Breakpoint.SM) 1.1.cssRem else 1.3.cssRem
    Column(
        modifier = modifier
            //.margin(leftRight = 40.px, bottom = 20.px)
            .margin(bottom = 20.px)
            .padding(all = 10.px)
            .border(2.px, LineStyle.Solid, ThemeColor.Grey.color)
            .borderRadius(r = 10.px).then(HoverColorStyle.toModifier()),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Box(
            modifier = Modifier.padding(topBottom = 5.px)
                .backgroundColor(ThemeColor.LightGreen.color)
                .clip(Circle())
                .size(50.px),
            contentAlignment = Alignment.Center
        ) {
            Image(
                src = imageUrl,
                width = 30,
                height = 30
            )
        }
        H4(
            attrs = SpanTextStyle
                .toModifier()
                .fontWeight(FontWeight.Bold)
                .textAlign(TextAlign.Center)
                .fontSize(titleFontSize)
                .fillMaxWidth()
                .margin(top = 5.px)
                .toAttrs()
        ) {
            Text(title)
        }
//        SpanText(
//            text = title,
//            modifier = Modifier
//                .fontSize(24.px)
//                .fontWeight(FontWeight.Bold)
//                .textAlign(TextAlign.Center)
//                .fillMaxWidth()
//                //.margin(top = 5.px)
//        )
        H5(
            attrs = SpanTextStyle
                .toModifier()
                .textAlign(TextAlign.Center)
                .fontSize(subtitleFontSize)
                .margin(top = 5.px)
                .fillMaxWidth().toAttrs()
        ) {
            Text(subTitle)
        }
//        SpanText(
//            text = subTitle,
//            modifier = Modifier
//                .fontSize(24.px)
//                .textAlign(TextAlign.Center)
//                .fillMaxWidth()
//        )
    }
}

val HoverColorStyle by ComponentStyle {
    hover { Modifier.backgroundColor(ThemeColor.LightGreen.color) }
}
