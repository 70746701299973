package com.cjrodriguezproductions.divine_motors.components

import androidx.compose.runtime.Composable
import com.cjrodriguezproductions.divine_motors.pages.ThemeColor
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.silk.components.icons.fa.FaBarsProgress
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun ProgressErrorSnackBar(
    modifier: Modifier = Modifier,
    shouldProgress: Boolean,
    errorMessage: String
){
    Box (
        modifier = modifier.backgroundColor(
            when {
                errorMessage.isNotEmpty() && errorMessage != "OK" -> Colors.PaleVioletRed
                shouldProgress && errorMessage.isEmpty() -> ThemeColor.DarkGreen.color
                errorMessage == "OK" -> Colors.LawnGreen
                else -> Colors.LawnGreen
            }
        ).borderRadius(10.px).fontSize(FontSize.Medium).height(80.px),
        contentAlignment = Alignment.Center
    ) {
        if (shouldProgress && errorMessage.isEmpty()){
            FaBarsProgress()
        } else {
            Text(errorMessage)
        }
    }
}

enum class ProgressState{
    LOADING,
    SUCCESS,
    FAILURE
}